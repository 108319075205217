import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import AuthContext from '../../store/auth-context';

function ProtectAuth() {
  const { isLoggedIn } = useContext(AuthContext);

  const location = useLocation();

  return isLoggedIn ? (
    <Navigate to="/user/dashboard" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
}

export default ProtectAuth;
